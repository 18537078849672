import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getTenantIdForDomain, graphQL_APIURI } from './helpers/tenantHelpers';
import { USER_TOKEN } from 'utils/constants';

import './index.css';
import App from './App';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Handle the specific error: Invalid token
    console.error(graphQLErrors);
    
    // Handle GraphQL errors globally
    graphQLErrors.forEach((error: any) => {
      // Check for specific error condition
      if (
        error.extensions &&
        error.extensions.code === 'INTERNAL_SERVER_ERROR' &&
        error.message.includes('Context creation failed') &&
        (
          error.message.includes('Invalid token') ||
          error.message.includes('expired token')
        )
      ) {
        // Handle the specific error: Invalid token
        console.error('GraphQL error: Context creation failed - Invalid token');

        // Update browser location
        window.location.href = '/logout'; // Full page reload
      } else {
        // Handle other GraphQL errors
        console.error('GraphQL error:', error);
      }
    });
  }

  if (networkError) {
    // Handle network errors globally
    console.error('Network error:', networkError);
  }
});


const httpLink = createHttpLink({
  uri: graphQL_APIURI,
  fetchOptions: {
    timeout: 10000, // Set your desired timeout in milliseconds
  },
});

const tenantId = getTenantIdForDomain();

// Use ApolloLink.concat to concatenate links
const concat = ApolloLink.concat;
const authLink = setContext((_, { headers }) => {
  const token = getToken();
  const authorizationHeader = token ? `Bearer ${token}` : '';

  return {
    headers: {
      ...headers,
      tenant: tenantId,
      Authorization: authorizationHeader,
    },
  };
});

const client = new ApolloClient({
  uri: graphQL_APIURI,
  link: concat(errorLink, authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

function getToken() {
  return localStorage.getItem(USER_TOKEN);
}
