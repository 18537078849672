import { hasIn } from 'ramda';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  RUTGERS_TENANT_ID,
  DEMO_TENANT_ID,
  TRIAL_TENANT_ID,
  CS_TENANT_ID,
} from 'utils/constants';

const domain = window.location.hostname;
const domainPort = window.location.port;

const localColoradoPort = 3000;
const localOregonPort = 3001;
const localVirginiaPort = 3002;
const localDartmouthPort = 3003;
const localRutgersPort = 3004;
const localDemoPort = 3005;
const localTrialPort = 3006;

const localCsPort = 3010;

export const currentEnvironment = `${process.env.REACT_APP_NODE_ENV}`;

/*
export const reactapp_APIURI = `${process.env.REACT_APP_API}`;

export const reactapp_COLORADOAPIURI = `${process.env.REACT_APP_API_COLORADO_URL}`;
export const reactapp_OREGONAPIURI = `${process.env.REACT_APP_API_OREGON_URL}`;
export const reactapp_VIRGINIAAPIURI = `${process.env.REACT_APP_API_VIRGINIA_URL}`;
export const reactapp_DARTMOUTHAPIURI = `${process.env.REACT_APP_API_DARTMOUTH_URL}`;
export const reactapp_DEMOAPIURI = `${process.env.REACT_APP_API_DEMO_URL}`;
export const reactapp_TRIALAPIURI = `${process.env.REACT_APP_API_TRIAL_URL}`;
export const reactapp_RUTGERSAPIURI = `${process.env.REACT_APP_API_RUTGERS_URL}`;
*/

export const reactapp_APIURL = getAPITenantIdForDomain();
export const graphQL_APIURI = reactapp_APIURL+'/graphql';

export const reactapp_S3_READURI = `${process.env.REACT_APP_S3_BUCKET}`;
export const cryptoKeyText = `${process.env.REACT_APP_AES_ENCRYPTION_KEY}`;

export const COLORADO_CLARITY_ID = `${process.env.REACT_APP_COLORADO_CLARITY_ID}`;
export const OREGON_CLARITY_ID = `${process.env.REACT_APP_OREGON_CLARITY_ID}`;
export const VIRGINIA_CLARITY_ID = `${process.env.REACT_APP_VIRGINIA_CLARITY_ID}`;
export const DARTMOUTH_CLARITY_ID = `${process.env.REACT_APP_DARTMOUTH_CLARITY_ID}`;


export function getTenantIdForDomain(): string {
  if (domain === 'localhost') {
    return getTenantIdForLocalhost();
  }

  if (currentEnvironment === 'staging' || currentEnvironment === 'development') {
    return getTenantForStaging();
  }

  if (currentEnvironment === 'production') {
    return getTenantForProduction();
  }

  return '';
}


function getTenantIdForLocalhost(): string {
  const port = Number(window.location.port);

  switch (port) {
    case localColoradoPort:
      return COLORADO_TENANT_ID;
    case localOregonPort:
      return OREGON_TENANT_ID;
    case localVirginiaPort:
      return VIRGINIA_TENANT_ID;
    case localDartmouthPort:
      return DARTMOUTH_TENANT_ID;
    case localRutgersPort:
        return RUTGERS_TENANT_ID;
    case localDemoPort:
      return DEMO_TENANT_ID;
    case localTrialPort:
      return TRIAL_TENANT_ID;
    case localCsPort:
      return CS_TENANT_ID;
    default:
      return '';
  }
}


export function getAPITenantIdForDomain(): string {
  if (currentEnvironment === 'development') {
    return getAPITenantForDevelopment();
  }

  if (currentEnvironment === 'staging' || currentEnvironment === '') {
    return getAPITenantForStaging();
  }

  if (currentEnvironment === 'production') {
    return getAPITenantForProduction();
  }

  return '';
}

function getAPITenantForDevelopment(): string {
  // console.log('`${domain.toLowerCase()}:${domainPort}`', `${domain.toLowerCase()}:${domainPort}`);
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3000') return 'http://localhost:4002';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3001') return 'http://localhost:4003';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3002') return 'http://localhost:4004';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3003') return 'http://localhost:4005';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3004') return 'http://localhost:4006';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3005') return 'http://localhost:4007';
  if (`${domain.toLowerCase()}:${domainPort}` === 'localhost:3006') return 'http://localhost:4008';

  return 'https://localhost:4002';
}

function getAPITenantForStaging(): string {
  if (domain.toLowerCase() === 'echo-n4e21x-colorado.cfwebtools.com') return 'https://echo-api-n4e21x-colorado.cfwebtools.com';
  if (domain.toLowerCase() === 'echo-88sjg2-oregon.cfwebtools.com') return 'https://echo-api-88sjg2-oregon.cfwebtools.com';
  if (domain.toLowerCase() === 'echo-qgbsdp-virginia.cfwebtools.com') return 'https://echo-api-qgbsdp-virginia.cfwebtools.com';
  if (domain.toLowerCase() === 'echo-x6waou-dartmouth.cfwebtools.com') return 'https://echo-api-x6waou-dartmouth.cfwebtools.com';
  if (domain.toLowerCase() === 'echo-9sj23a-rutgers.cfwebtools.com') return 'https://echo-api-9sj23a-rutgers.cfwebtools.com';

  if (domain.toLowerCase() === 'echo-demo.cfwebtools.com') return 'https://echo-api-demo.cfwebtools.com';
  if (domain.toLowerCase() === 'echo-trial.cfwebtools.com') return 'https://echo-api-trial.cfwebtools.com';

  return 'https://echo-api.cfwebtools.com';
}

function getAPITenantForProduction(): string {
  if (domain.toLowerCase() === 'connect.echocolorado.org') return 'https://api.echocolorado.org';
  if (domain.toLowerCase() === 'connect.oregonechonetwork.org') return 'https://api.oregonechonetwork.org';
  if (domain.toLowerCase() === 'connect.virginiaprojectecho.org') return 'https://api.virginiaprojectecho.org';
  if (domain.toLowerCase() === 'connect.echodartmouth-hitchcock.org') return 'https://api.echodartmouth-hitchcock.org';

  if (domain.toLowerCase() === 'demo.echocolorado.org') return 'https://demo-api.echocolorado.org';

  return 'https://api.echocolorado.org';
}

function getTenantForStaging(): string {
  if (domain.toLowerCase() === 'echo-n4e21x-colorado.cfwebtools.com') return COLORADO_TENANT_ID;
  if (domain.toLowerCase() === 'echo-88sjg2-oregon.cfwebtools.com') return OREGON_TENANT_ID;
  if (domain.toLowerCase() === 'echo-qgbsdp-virginia.cfwebtools.com') return VIRGINIA_TENANT_ID;
  if (domain.toLowerCase() === 'echo-x6waou-dartmouth.cfwebtools.com') return DARTMOUTH_TENANT_ID;
  if (domain.toLowerCase() === 'echo-9sj23a-rutgers.cfwebtools.com') return RUTGERS_TENANT_ID;
  
  if (domain.toLowerCase() === 'echo-demo.cfwebtools.com') return DEMO_TENANT_ID;
  if (domain.toLowerCase() === 'echo-trial.cfwebtools.com') return TRIAL_TENANT_ID;

  return CS_TENANT_ID;
}

function getTenantForProduction(): string {
  if (domain.toLowerCase() === 'connect.echocolorado.org') return COLORADO_TENANT_ID;
  if (domain.toLowerCase() === 'connect.oregonechonetwork.org') return OREGON_TENANT_ID;
  if (domain.toLowerCase() === 'connect.virginiaprojectecho.org') return VIRGINIA_TENANT_ID;
  if (domain.toLowerCase() === 'connect.echodartmouth-hitchcock.org') return DARTMOUTH_TENANT_ID;
  if (domain.toLowerCase() === 'demo.echocolorado.org') return DEMO_TENANT_ID;
  
  return CS_TENANT_ID;
}

