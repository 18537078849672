import crypto from 'crypto';

import { cryptoKeyText } from 'helpers/tenantHelpers';

interface PasswordValidation {
  isValid: boolean;
  message: string;
}

export const validatePassword = (password: string, confirmPassword: string): PasswordValidation => {
  let isValid = false;
  let message = '';

  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{5,})');
  isValid = strongRegex.test(password.trim());
  if (!isValid) {
    message =
      'Password must be at least 6 characters, have at least 1 lowercase character, at least 1 uppercase character, at least 1 number, and at least one special character.';
  }

  if (password !== confirmPassword) {
    isValid = false;
    message = 'Password and Confirm Password do not match';
  }

  return {
    isValid,
    message,
  };
};

export const encrypt = (text: string) => {
  try {
    const algorithm = 'aes-256-cbc';
    // Convert the key text to a Buffer object in hexadecimal format
    const cryptokey = Buffer.from(cryptoKeyText, 'hex');
    // Generate a random IV
    const iv = crypto.randomBytes(16);

    // Convert the key to a readable format if needed (optional)
    const keyStr = cryptokey.toString('hex'); // This is for demonstration only, avoid displaying the key in logs
    const ivStr = iv.toString('hex');
    
    const cipher = crypto.createCipheriv(algorithm, cryptokey, iv);
    let encrypted = cipher.update(text, 'utf8', 'hex');
    encrypted += cipher.final('hex');

    // Combine encrypted text and IV for efficient storage/transmission
    const encryptedWithIV = `${encrypted}::${ivStr}`;

    return encryptedWithIV;
  } catch (error) {
    console.error("Encryption error:", error);
    // Handle the error gracefully, e.g., return null or throw a custom error
  }

  return text;
};
