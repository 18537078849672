import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Alert, Container } from '@mui/material';

import PageHeader from '../../../components/global/PageHeader';
import LearnerAccountDetail from '../../../components/account/LearnerAccountDetail/LearnerAccountDetail';
import ProgressIndicator from '../../../components/global/ProgressIndicator';

import {
  AddOrUpdateUserInput,
  UpdateUserVariables,
  GetUserById,
  GetUserByIdVariables,
} from '../../../models/GeneratedModels';
// import { useAuth } from '../../../hooks/useAuth';
import {
  getUserById_Gql,
  getUserById_Name,
} from '../../../gql/user/getUserById';
import { updateUser_Gql } from '../../../gql/user/updateUser';
import EditAccountParams from '../../../models/params/EditAccountParams';

// interface EditAccountProps {
//   userId: string;
// }

const EditAccount = () => {
  const { userId } = useParams<EditAccountParams>();
  const history = useHistory();
  // const auth = useAuth();

  const [formError, setFormError] = useState('');

  const { data, loading } = useQuery<GetUserById, GetUserByIdVariables>(
    getUserById_Gql,
    {
      variables: {
        id: userId,
      },
    }
  );

  const user = data?.getUserById;

  const convertUserToEdit = (): AddOrUpdateUserInput => {
    return {
      city: data?.getUserById?.city ?? '',
      countyId: data?.getUserById?.countyId ?? '',
      credentials: data?.getUserById?.userCredentialIds,
      email: data?.getUserById?.email ?? '',
      ethnicities: data?.getUserById?.userEthnicityIds,
      firstName: data?.getUserById?.firstName ?? '',
      id: data?.getUserById?.id ?? '',
      lastName: data?.getUserById?.lastName ?? '',
      organization: data?.getUserById?.organization ?? '',
      organizationAddress1: data?.getUserById?.organizationAddress1 ?? '',
      organizationAddress2: data?.getUserById?.organizationAddress2 ?? '',
      otherCredential: data?.getUserById?.otherCredential ?? '',
      otherEthnicity: data?.getUserById?.otherEthnicity ?? '',
      otherProfession: data?.getUserById?.otherProfession ?? '',
      otherMarketingSource: data?.getUserById?.otherMarketingSource ?? '',
      otherWorkSetting: data?.getUserById?.otherWorkSetting ?? '',
      primaryCare: data?.getUserById?.primaryCare,
      professionId: data?.getUserById?.professionId ?? '',
      provideDirectCare: data?.getUserById?.provideDirectCare,
      provideMedicaid: data?.getUserById?.provideMedicaid,
      receiveEmails: data?.getUserById?.receiveEmails ?? false,
      secondLevelProfessionId: data?.getUserById?.secondLevelProfessionId ?? '',
      state: data?.getUserById?.state ?? '',
      thirdLevelProfessionId: data?.getUserById?.thirdLevelProfessionId ?? '',
      zip: data?.getUserById?.zip ?? '',
      marketingSourceIds: data?.getUserById?.marketingSourceIds ?? [],
      organizationDescriptionIds:
        data?.getUserById?.organizationDescriptionIds ?? [],
      roleDescriptionId: data?.getUserById?.roleDescriptionId ?? '',
      sexId: data?.getUserById?.sexId ?? '',
      otherSex: data?.getUserById?.otherSex ?? '',
      firstSeries: data?.getUserById?.firstSeries,
      birthYear: data?.getUserById?.birthYear,
      professionStartYear: data?.getUserById?.professionStartYear,
      phone: data?.getUserById?.phone ?? '',
      workSettingIds: data?.getUserById?.workSettingIds ?? [],
    };
  };

  const [updateUserAccount, { error, loading: updateLoading }] = useMutation<
    void,
    UpdateUserVariables
  >(updateUser_Gql);

  const updateUser = async (user: AddOrUpdateUserInput) => {
    setFormError('');

    try {
      await updateUserAccount({
        variables: {
          user,
        },
        refetchQueries: [getUserById_Name],
      });

      if (!error) {
        history.push('/admin/accounts');
      }
    } catch (error: any) {
      setFormError(error.graphQLErrors[0].message);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 12 }}>
        {user && (
          <PageHeader
            title={`Edit ${user.firstName} ${user.lastName}`}
          ></PageHeader>
        )}

        {formError.length > 0 && <Alert severity="error">{formError}</Alert>}

        <ProgressIndicator isOpen={loading} title="Loading..." />

        <ProgressIndicator isOpen={updateLoading} title="Saving..." />

        <Box sx={{ mt: 3 }}>
          {!loading && (
            <LearnerAccountDetail
              userAccount={convertUserToEdit()}
              onSave={updateUser}
              buttonText="Save Account"
              submissionError={formError}
              mode="update"
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default EditAccount;
