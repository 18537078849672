import axios from 'axios';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { useAuth } from './useAuth';

export default function useAxios() {
  const auth = useAuth();
  const userToken = auth.user?.token ?? '';

  const get = async (url: string, headers: any = null, useToken: boolean = true) => {
    const options = getAxiosOptions(useToken, userToken, headers);

    const result = await axios.get(url, options);
    return result.data;
  };

  const post = async (url: string, data: any, headers: any = null, useToken: boolean = true) => {
    const options = getAxiosOptions(useToken, userToken, headers);
    const result = await axios.post(url, data, options);

    return result.data;
  };

  const put = async (url: string, data: any, headers: any = null, useToken: boolean = true) => {
    const options = getAxiosOptions(useToken, userToken, headers);
    const result = await axios.put(url, data, options);

    return result.data;
  };

  return { get, post, put };
}

function getAxiosOptions(useToken: boolean, token: string, additionalHeaders: any = null) {
  if (useToken) {
    return {
      headers: {
        ...additionalHeaders,
        tenant: getTenantIdForDomain(),
        Authorization: useToken ? `Bearer ${token}` : '',
      },
    };
  }

  return {
    headers: {
      ...additionalHeaders,
      tenant: getTenantIdForDomain(),
    },
  };
}
