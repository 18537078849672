import { gql } from '@apollo/client';

export const getUserById_Name = 'GetUserById';
export const getUserById_Gql = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      id
      firstName
      lastName
      email
      otherCredential
      otherWorkSetting
      professionId
      secondLevelProfessionId
      thirdLevelProfessionId
      otherProfession
      otherEthnicity
      otherMarketingSource
      organization
      organizationAddress1
      organizationAddress2
      city
      state
      countyId
      zip
      provideDirectCare
      provideMedicaid
      primaryCare
      createdAt
      updatedAt
      credentials
      receiveEmails
      userCredentialIds
      userEthnicityIds
      marketingSourceIds
      organizationDescriptionIds
      workSettingIds
      userTenantIds
      roleDescriptionId
      sexId
      otherSex
      firstSeries
      birthYear
      professionStartYear
      phone
    }
  }
`;
