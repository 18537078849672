import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Box,
  TextField,
  FormHelperText,
  Grid,
  Alert,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel, 
  FormControl, 
  Button, 
  SelectChangeEvent,
} from '@mui/material';
import InputMask from 'react-input-mask';

import { getAccountLookupsQuery_Gql } from '../../../gql/user/getAccountLookupsQuery';
import {
  GetAccountLookups,
  GetAccountLookups_getAccountLookups_professions_secondLevelProfessions,
  GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions,
  AddOrUpdateUserInput,
} from '../../../models/GeneratedModels';
import {
  getSecondaryProfessions,
  getTertiaryProfessions,
  hasOtherProfession,
} from './professionHelpers';
import { getCountiesByState } from '../../../helpers/countyHelpers';
import State from '../../../models/State.model';
import County from '../../../models/County.model';
import states from '../../../data/states.json';
import SectionHeader from './components/SectionHeader';
import {
  getCredentialNamesFromValues,
  getEthnicityNamesFromValues,
  validateUserForm,
  hasOtherCredentialSelected,
  hasOtherEthnicitySelected,
  hasOtherMarketingSourceSelected,
  hasOtherWorkSettingSelected,
} from './LearnerAccountDetails.helpers';
import { LearnerAccountDetailProps } from './LearnerAccountDetail.interfaces';
import PasswordRequirements from '../Password/PasswordRequirements';
import TenantUserFields from './TenantUserFields';
import TenantOrgFields from './TenantOrgFields';
import TenantCareFields from './TenantCareFields';

import { VIRGINIA_TENANT_ID } from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';

const R = require('ramda');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    accountText: {
      width: '600px',
      marginBottom: 2,
    },
    accountSelect: {
      width: '600px',
      marginBottom: 2,
      textAlign: 'left',
    },
    select: {
      width: '600px',
      marginBottom: 2,
      textAlign: 'left',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LearnerAccountDetail = ({
  userAccount,
  onSave,
  buttonText,
  submissionError,
  mode,
}: LearnerAccountDetailProps) => {
  const tenantId = getTenantIdForDomain();
  const classes = useStyles();

  // Define state variables

  const [user, setUser] = useState<AddOrUpdateUserInput>(userAccount);
  const [validationError, setValidationError] = useState<string[]>([]);

  const [selectedCredentials, setSelectedCredentials] = useState<string[]>(
    user.credentials ?? []
  );
  const [selectedEthnicities, setSelectedEthnicities] = useState<string[]>(
    user.ethnicities ?? []
  );

  const [selectedState, setSelectedState] = useState(user.state);
  const [counties, setCounties] = useState<County[]>([]);
  const [selectedCounty, setSelectedCounty] = useState(user.countyId);
  const [phoneNumber, setPhoneNumber] = useState(user.phone || '');

  const [secondLevelProfessionList, setSecondLevelProfessionList] = useState<
    GetAccountLookups_getAccountLookups_professions_secondLevelProfessions[]
  >([]);

  const [tertiaryProfessionList, setTertiaryProfessionList] = useState<
    GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions[]
  >([]);

  // State for other fields
  const [showOtherCredential, setShowOtherCredential] = useState(false);
  const [showOtherEthnicity, setShowOtherEthnicity] = useState(false);
  const [showOtherProfession, setShowOtherProfession] = useState(false);
  const [showOtherMarketingSource, setShowOtherMarketingSource] =
    useState(false);
  const [showOtherWorkSetting, setShowOtherWorkSetting] = useState(false);

  // Get account lookup fields
  const { error, data } = useQuery<GetAccountLookups>(
    getAccountLookupsQuery_Gql
  );

  const credentials = useMemo(
    () => data?.getAccountLookups?.credentials ?? [],
    [data]
  );
  const professions = useMemo(
    () => data?.getAccountLookups?.professions ?? [],
    [data]
  );
  const ethnicities = useMemo(
    () => data?.getAccountLookups?.ethnicities ?? [],
    [data]
  );
  const workSettings = useMemo(
    () => data?.getAccountLookups?.workSettings ?? [],
    [data]
  );
  const sexs = useMemo(() => data?.getAccountLookups?.sexs ?? [], [data]);
  const marketingSources = useMemo(
    () => data?.getAccountLookups?.marketingSources ?? [],
    [data]
  );
  const organizationDescriptions = useMemo(
    () => data?.getAccountLookups?.organizationDescriptions ?? [],
    [data]
  );
  const roleDescriptions = useMemo(
    () => data?.getAccountLookups?.roleDescriptions ?? [],
    [data]
  );
  const isHome = useMemo(
    () =>
      (user.userTenantIds || []).length > 0
        ? (user.userTenantIds || []).includes(tenantId)
        : true,
    [user, tenantId]
  );

  useEffect(() => {
    setUser(userAccount);

    async function getCounties() {
      const counties = await getCountiesByState(userAccount.state ?? '');
      setCounties(counties);
    }

    getCounties();
    setShowOtherCredential(
      hasOtherCredentialSelected(userAccount.credentials ?? [], credentials)
    );
    setShowOtherMarketingSource(
      hasOtherMarketingSourceSelected(
        userAccount.marketingSourceIds ?? [],
        marketingSources
      )
    );
    setShowOtherWorkSetting(
      hasOtherWorkSettingSelected(
        userAccount.workSettingIds ?? [],
        workSettings
      )
    );
    setShowOtherEthnicity(
      hasOtherEthnicitySelected(userAccount.ethnicities ?? [], ethnicities)
    );
    setShowOtherProfession(
      hasOtherProfession(
        userAccount.professionId ?? '',
        userAccount.secondLevelProfessionId ?? '',
        userAccount.thirdLevelProfessionId ?? '',
        professions
      )
    );

    if (userAccount.professionId && userAccount.professionId.length > 0) {
      setSecondLevelProfessionList(
        getSecondaryProfessions(userAccount.professionId, professions)
      );
    }

    if (
      userAccount.secondLevelProfessionId &&
      userAccount.secondLevelProfessionId.length > 0
    ) {
      setTertiaryProfessionList(
        getTertiaryProfessions(
          userAccount.professionId ?? '',
          userAccount.secondLevelProfessionId,
          professions
        )
      );
    }
  }, [credentials, ethnicities, marketingSources, professions, userAccount, workSettings]);

  const getNoneOfAbove = (items: any[]) =>
    items.find((item: any) => item.name === 'None of the Above');

  const onFormElementChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === 'email') {
      value = (e.target.value).trim();
    }

    setUser({
      ...user,
      [name]: value,
    });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setUser({
      ...user,
      [name]: checked,
    });
  };

  const handleCredentialChange = (
    event: SelectChangeEvent<typeof selectedCredentials>
  ) => {
    const {
      target: { value },
    } = event;

    const tempCredentials =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedCredentials(tempCredentials);
    setUser({
      ...user,
      credentials: tempCredentials
    });

    setShowOtherCredential(
      hasOtherCredentialSelected(tempCredentials, credentials)
    );
  };

  const handleEthnicityChange = (
    event: SelectChangeEvent<typeof selectedEthnicities>
  ) => {
    const {
      target: { value },
    } = event;

    const tempEthnicities =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedEthnicities(tempEthnicities);
    setUser({
      ...user,
      ethnicities: tempEthnicities
    });

    // let tempShowOtherEthnicity = false;
    // for (let ethnicityId of tempEthnicities) {
    //   const ethnicitiesIndex = R.findIndex(R.propEq('id', ethnicityId))(
    //     ethnicities
    //   );

    //   if (ethnicities[ethnicitiesIndex]?.isOther) {
    //     tempShowOtherEthnicity = true;
    //     break;
    //   }
    // }

    setShowOtherEthnicity(hasOtherEthnicitySelected(tempEthnicities, ethnicities));
  };

  const handleProfessionChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      professionId: value,
      secondLevelProfessionId: '',
      thirdLevelProfessionId: '',
    });

    // Reset dependant fields
    setSecondLevelProfessionList([]);
    setTertiaryProfessionList([]);

    setShowOtherProfession(hasOtherProfession(value, '', '', professions));
    setSecondLevelProfessionList(getSecondaryProfessions(value, professions));
  };

  const handleSecondLevelProfessionChange = (
    event: SelectChangeEvent<string>
  ) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      secondLevelProfessionId: value,
      thirdLevelProfessionId: '',
    });

    // Reset dependant fields
    setTertiaryProfessionList([]);

    setShowOtherProfession(
      hasOtherProfession(user.professionId || '', value, '', professions)
    );
    setTertiaryProfessionList(
      getTertiaryProfessions(user.professionId || '', value, professions)
    );
  };

  const handleThirdLevelProfessionChange = (
    event: SelectChangeEvent<string>
  ) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      thirdLevelProfessionId: value,
    });

    setShowOtherProfession(
      hasOtherProfession(
        user.professionId || '',
        user.secondLevelProfessionId || '',
        value,
        professions
      )
    );
  };

  const handleStateChange = async (
    event: SelectChangeEvent<typeof selectedState>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedState(value);

    setUser({
      ...user,
      state: value,
    });

    const counties = await getCountiesByState(value ?? '');
    setCounties(counties);
  };

  const handleCountyChange = async (
    event: SelectChangeEvent<typeof selectedCounty>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedCounty(value);
    setUser({
      ...user,
      countyId: value,
    });
  };

  const handleSexChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      sexId: value,
    });
  };

  const handleMarketingSourceIdChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    const ids = typeof value === 'string' ? value.split(',') : value;

    setShowOtherMarketingSource(
      hasOtherMarketingSourceSelected(ids, marketingSources)
    );

    setUser({
      ...user,
      marketingSourceIds: ids,
    });
  };

  const handleFirstTimeChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      firstSeries: value === 'yes' ? true : false,
    });
  };

  const handleStartYearChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      professionStartYear: Number(value),
    });
  };

  const handleRoleDescChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      roleDescriptionId: value,
    });
  };

  const handleOrgDescChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    const noa = getNoneOfAbove(organizationDescriptions);
    let ids = [];
    if (value.indexOf(noa.id) > -1) {
      ids = [noa.id];
    } else {
      ids = typeof value === 'string' ? value.split(',') : value;
    }
    setUser({
      ...user,
      organizationDescriptionIds: ids,
    });
  };

  const handleWorkSettingsChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    const ids = typeof value === 'string' ? value.split(',') : value;

    setShowOtherWorkSetting(hasOtherWorkSettingSelected(ids, workSettings));

    setUser({
      ...user,
      workSettingIds: ids,
    });
  };

  const handleBirthYearChange = async (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setUser({
      ...user,
      birthYear: Number(value),
    });
  };

  const phoneLabel = tenantId === VIRGINIA_TENANT_ID ? 'Preferred Work Number' : 'Phone Number';
  const phoneMask = '(000) 000-0000';

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    const value = e.target.value;
    setPhoneNumber(value);
    
    setUser({
      ...user,
      phone: `${value.replace(/\/d/g, "").replace(/\//g, '').replace('undefined', '')}`,
    });
  }; //newPhoneNumber.replace(/\D/g, '')

  const onPrimaryCareChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setUser({
      ...user,
      primaryCare: value === 'yes' ? true : false,
    });
  };

  const onProvideMedicaidChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setUser({
      ...user,
      provideMedicaid: value === 'yes' ? true : false,
    });
  };

  const onDirectCareChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setUser({
      ...user,
      provideDirectCare: value === 'yes' ? true : false,
    });
  };

  const validateForm = () => {
    setValidationError([]);

    const validationErrors: string[] = [];

    validationErrors.push(
      ...validateUserForm(user, credentials, ethnicities, professions, mode)
    );

    // if (!validateUserForm(user, credentials, ethnicities, mode)) {
    //   setValidationError('All fields are required');
    //   return false;
    // }

    // if (mode === 'create') {
    //   const validPassword = validatePassword(user.password ?? '', user.confirmPassword ?? '');
    //   if (!validPassword.isValid) {
    //     validationErrors.push('Password is not valid');

    //     // setValidationError(validPassword?.errorMsg ?? '');
    //     // return false;
    //   }
    // }

    if (validationErrors.length > 0) {
      setValidationError(validationErrors);
      return false;
    }

    return true;
  };

  const saveUser = () => {
    if (validateForm()) {
      onSave(user);
    }
  };

  return (
    <>
      {error && (
        <Alert severity="error">{error.graphQLErrors[0].message}</Alert>
      )}
        <Box sx={{ mt: 3, pb: 2, textAlign: 'center' }}>
          <Typography
            variant="subtitle1"
            sx={{ color: '#be051b', mb: 1, textAlign: 'left' }}
          >
            * - all fields required
          </Typography>

          <SectionHeader title="Account Information" />

          {/* Account Information */}
          <Grid container spacing={2} sx={{ pb: 2, mt: 1, textAlign: 'center' }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <TextField
                required
                className={classes.accountText}
                label="First Name"
                name="firstName"
                value={user.firstName}
                onChange={onFormElementChange}
                color="warning"
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <TextField
                required
                className={classes.accountText}
                label="Last Name"
                name="lastName"
                value={user.lastName}
                onChange={onFormElementChange}
                color="warning"
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <TextField
                required
                className={classes.accountText}
                label="Email"
                name="email"
                value={user.email}
                onChange={onFormElementChange}
                color="warning"
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <InputMask
              mask="(999) 999-9999"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maskChar=" "
            >
              {() => (
                <TextField
                className={classes.accountSelect}
                label={phoneLabel}
                name="phone"
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required={tenantId === VIRGINIA_TENANT_ID ? undefined : true}
                />
              )}
            </InputMask>
            </Grid>
          </Grid>

          {mode === 'create' && (
            <Grid container spacing={2} sx={{ pb: 2, mt: 1, textAlign: 'center' }}>
              <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
                <TextField
                  required
                  className={classes.accountText}
                  label="Password"
                  name="password"
                  type="password"
                  value={user.password}
                  onChange={onFormElementChange}
                  color="warning"
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
                <TextField
                  required
                  className={classes.accountText}
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={user.confirmPassword}
                  onChange={onFormElementChange}
                  color="warning"
                />
              </Grid>
              <Grid item lg={12} xs={12} sx={{ mb: 1 }}>
                <PasswordRequirements />
              </Grid>
            </Grid>
          )}
        </Box>

      {/* About Me */}
      {isHome && (
        <Box sx={{ mt: 3, pb: 2, textAlign: 'center' }}>
          <SectionHeader title="About Me" />
          <Grid container>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <FormControl sx={{ mb: 1 }}>
                <InputLabel required id="credentials-label" color="warning">
                  Credentials
                </InputLabel>
                <Select
                  required
                  className={classes.accountSelect}
                  labelId="credentials-label"
                  multiple
                  value={selectedCredentials}
                  onChange={handleCredentialChange}
                  input={<OutlinedInput label="Credentials" />}
                  renderValue={(selected) =>
                    getCredentialNamesFromValues(credentials, selected)
                  }
                  MenuProps={MenuProps}
                  color="warning"
                >
                  {credentials.map((credential) => (
                    <MenuItem key={credential.id} value={credential.id}>
                      <Checkbox
                        checked={
                          selectedCredentials.indexOf(credential.id) > -1
                        }
                      />
                      <ListItemText primary={credential.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {showOtherCredential && (
                <TextField
                  required
                  className={classes.accountText}
                  label="Other Credential"
                  name="otherCredential"
                  value={user.otherCredential}
                  onChange={onFormElementChange}
                  color="warning"
                  sx={{ textAlign: 'center' }}
                />
              )}
            </Grid>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <FormControl sx={{ mb: 1 }}>
                <InputLabel required id="ethnicities-label" color="warning">
                  Race/Ethnicity
                </InputLabel>
                <Select
                  required
                  className={classes.accountSelect}
                  labelId="ethnicities-label"
                  multiple
                  value={selectedEthnicities}
                  onChange={handleEthnicityChange}
                  input={<OutlinedInput label="Race/Ethnicity" />}
                  renderValue={(selected) =>
                    getEthnicityNamesFromValues(ethnicities, selected)
                  }
                  MenuProps={MenuProps}
                  color="warning"
                >
                  {ethnicities.map((ethnicity) => (
                    <MenuItem key={ethnicity.id} value={ethnicity.id}>
                      <Checkbox
                        checked={selectedEthnicities.indexOf(ethnicity.id) > -1}
                      />
                      <ListItemText primary={ethnicity.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {showOtherEthnicity && (
                <TextField
                  required
                  className={classes.accountText}
                  label="Other Ethnicity"
                  name="otherEthnicity"
                  value={user.otherEthnicity}
                  onChange={onFormElementChange}
                  color="warning"
                />
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <FormControl sx={{ mb: 1 }}>
                <InputLabel required id="professions-label" color="warning">
                  Profession
                </InputLabel>
                <Select
                  required
                  className={classes.accountSelect}
                  labelId="professions-label"
                  value={user.professionId || ''}
                  input={<OutlinedInput label="Profession" />}
                  onChange={handleProfessionChange}
                  color="warning"
                >
                  {professions.map((profession) => (
                    <MenuItem key={profession.id} value={profession.id}>
                      {profession.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {secondLevelProfessionList.length > 0 && (
                <>
                  <br />
                  <FormControl sx={{ mb: 1 }}>
                    <InputLabel
                      required
                      id="secondary-professions-label"
                      color="warning"
                    >
                      Secondary Profession
                    </InputLabel>
                    <Select
                      required
                      className={classes.accountSelect}
                      labelId="secondary-professions-label"
                      value={user.secondLevelProfessionId || ''}
                      onChange={handleSecondLevelProfessionChange}
                      color="warning"
                    >
                      {secondLevelProfessionList.length > 0 &&
                        secondLevelProfessionList.map((profession) => (
                          <MenuItem key={profession.id} value={profession.id}>
                            {profession.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <br />
                </>
              )}

              {tertiaryProfessionList && tertiaryProfessionList.length > 0 && (
                <FormControl sx={{ mb: 1 }}>
                  <InputLabel
                    required
                    id="third-professions-label"
                    color="warning"
                  >
                    Tertiary Profession
                  </InputLabel>
                  <Select
                    required
                    className={classes.accountSelect}
                    labelId="third-professions-label"
                    value={user.thirdLevelProfessionId || ''}
                    onChange={handleThirdLevelProfessionChange}
                    color="warning"
                  >
                    {tertiaryProfessionList.map((profession) => (
                      <MenuItem key={profession.id} value={profession.id}>
                        {profession.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {showOtherProfession && (
                <TextField
                  required
                  className={classes.accountText}
                  label="Other Profession"
                  name="otherProfession"
                  value={user.otherProfession}
                  onChange={onFormElementChange}
                  color="warning"
                />
              )}
            </Grid>
            <TenantUserFields
              isHome={isHome}
              user={user}
              classes={classes}
              sexs={sexs}
              marketingSources={marketingSources}
              roleDescriptions={roleDescriptions}
              onSexChange={handleSexChange}
              onMarketingSourceIdsChange={handleMarketingSourceIdChange}
              onFirstTimeChange={handleFirstTimeChange}
              onStartYearChange={handleStartYearChange}
              onRoleDescChange={handleRoleDescChange}
              onBirthYearChange={handleBirthYearChange}
              showOtherMarketingSource={showOtherMarketingSource}
              onFormElementChange={onFormElementChange}
            />
            {/* <Grid item lg={6} xs={12} sx={{ mb: 1 }}></Grid> */}
          </Grid>
        </Box>
      )}

      {/* My Organization */}
      <Box sx={{ mt: 3, pb: 2, textAlign: 'center' }}>
        <SectionHeader title="My Organization" />

        <Grid container>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <TextField
              required
              className={classes.accountText}
              label="Organization/Employer"
              name="organization"
              value={user.organization}
              onChange={onFormElementChange}
              color="warning"
            />
          </Grid>
          <TenantOrgFields
            isHome={isHome}
            user={user}
            organizationDescriptions={organizationDescriptions}
            workSettings={workSettings}
            classes={classes}
            onOrgDescChange={handleOrgDescChange}
            onWorkSettingChange={handleWorkSettingsChange}
            showOtherWorkSetting={showOtherWorkSetting}
            onFormElementChange={onFormElementChange}
          />
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <TextField
              required
              className={classes.accountText}
              label="Organization Address 1"
              name="organizationAddress1"
              value={user.organizationAddress1}
              onChange={onFormElementChange}
              color="warning"
            />
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <TextField
              className={classes.accountText}
              label="Organization Address 2"
              name="organizationAddress2"
              value={user.organizationAddress2}
              onChange={onFormElementChange}
              color="warning"
            />
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <TextField
              required
              className={classes.accountText}
              label="City"
              name="city"
              value={user.city}
              onChange={onFormElementChange}
              color="warning"
            />
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <FormControl>
              <InputLabel required id="state-select-label" color="warning">
                State
              </InputLabel>
              <Select
                required
                className={classes.accountSelect}
                labelId="state-select-label"
                id="state-select"
                label="State"
                value={user.state || ''}
                onChange={handleStateChange}
                color="warning"
              >
                {states.map((state: State) => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            {counties.length > 0 && (
              <FormControl>
                <InputLabel required id="county-select-label" color="warning">
                  County
                </InputLabel>
                <Select
                  required
                  className={classes.accountSelect}
                  labelId="county-select-label"
                  id="county-select"
                  label="County"
                  disabled={!counties.length}
                  value={user.countyId || ''}
                  onChange={handleCountyChange}
                  color="warning"
                >
                  {counties.map((county: County) => (
                    <MenuItem key={county.id} value={county.id}>
                      {county.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <TextField
              required
              className={classes.accountText}
              label="Zip"
              name="zip"
              value={user.zip}
              onChange={onFormElementChange}
              color="warning"
            />
          </Grid>
        </Grid>

        <br />

        <Grid container>
          <TenantCareFields
            classes={classes}
            user={user}
            onPrimaryCareChange={onPrimaryCareChange}
            onProvideMedicaidChange={onProvideMedicaidChange}
            onDirectCareChange={onDirectCareChange}
          />
          {/* <Grid item lg={6} xs={12} sx={{ mb: 1, textAlign: 'left' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" color="warning">
                Do you provide direct care to patients/clients?
              </FormLabel>
              <RadioGroup
                value={user.provideDirectCare}
                onChange={onRadioElementChange}
                row
                aria-label="gender"
                name="provideDirectCare"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <br />
            <br />

            <FormControl component="fieldset">
              <FormLabel component="legend" color="warning">
                Are you a Medicaid provider?
              </FormLabel>
              <RadioGroup
                value={user.provideMedicaid}
                onChange={onRadioElementChange}
                row
                aria-label="gender"
                name="provideMedicaid"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" color="warning">
                Do you work in a primary care setting?
              </FormLabel>
              <RadioGroup
                value={user.primaryCare}
                onChange={onRadioElementChange}
                row
                aria-label="gender"
                name="primaryCare"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              name="receiveEmails"
              checked={user.receiveEmails || false}
              value={user.receiveEmails}
              onChange={onCheckboxChange}
            />
          }
          label="Yes, I want to receive ECHO emails"
        />
        <br />
        <br />

        {validationError.length > 0 && (
          <Alert sx={{ mb: 2 }} severity="error">
            <Box sx={{ ml: 2, textAlign: 'left' }}>
              <ul>
                {validationError.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Box>
          </Alert>
        )}

        {submissionError.length > 0 && (
          <Alert sx={{ mb: 2 }} severity="error">
            {submissionError}
          </Alert>
        )}

        <Button
          sx={{ mb: 6 }}
          variant="contained"
          color="primary"
          onClick={saveUser}
        >
          {buttonText}
        </Button>
      </Box>
    </>
  );
};

export default LearnerAccountDetail;
