import { useMutation } from '@apollo/client';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';
import { AddResource, AddResourceVariables } from 'models/GeneratedModels';
import { addResource_Gql } from 'gql/programResources/addResource';
import { getProgramById_Name } from 'gql/program/getProgramByIdQuery';
import { reactapp_APIURL } from '../../../../../helpers/tenantHelpers';

import useAxios from 'hooks/useAxios';



export function useUploadResources() {
  const [saveResource, { loading: uploadingFiles }] = useMutation<AddResource, AddResourceVariables>(addResource_Gql, {
    refetchQueries: [getResourceForProgram_Name, getProgramById_Name],
  });

  const { get, put } = useAxios();

  const addResourcesToS3AndDB = async (programId: string, files: File[]) => {
    if (files === null || files.length === 0) return;

    for (let file of files) {
      try {
        const url = `${reactapp_APIURL}/resource/upload?programId=${programId}&fileName=${file.name}&fileType=${file.type}`;
        const preSignedUrl = (await get(url)).data;
        const options = {
          // headers: {
          //  'Content-Type': file.type,
          //} 
        };
        
        await put(preSignedUrl, file, options, false);

        await saveResource({
          variables: {
            resource: {
              name: file.name,
              programId,
            },
          },
        });
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  return { addResourcesToS3AndDB, uploadingFiles };
}
