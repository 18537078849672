import React, { useState, useEffect } from 'react';
import isEmail from 'validator/es/lib/isEmail';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';
import { initiateForgotPassword_Gql } from 'gql/user/initiateForgotPassword';
import { InitiateForgotPassword, InitiateForgotPasswordVariables } from 'models/GeneratedModels';
import { Container } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('A valid email address is required');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [resetPassword, { loading, data, error }] = useMutation<
    InitiateForgotPassword,
    InitiateForgotPasswordVariables
  >(initiateForgotPassword_Gql);

  useEffect(() => {
    if (data && data.initiateForgotPassword) {
      const { isSuccess, message } = data.initiateForgotPassword;
      if (!isSuccess) {
        setHasError(true);
        setErrorMessage(message ?? 'An unknown error occurred');
      } else {
        setIsSubmitted(true);
      }
    }
  }, [data]);

  const onFormKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      await resetPasswordClick();
    }
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateForm = (): boolean => {
    setHasError(false);

    if (email.trim().length === 0) {
      setHasError(true);
      return false;
    }

    if (!isEmail(email)) {
      setHasError(true);
      return false;
    }

    return true;
  };

  const resetPasswordClick = async () => {
    if (validateForm()) {
      await resetPassword({
        variables: {
          email,
        },
      });
    }
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Sending..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <PageHeader title="Reset Your Password" />

        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {!isSubmitted && (
              <Stack spacing={2}>
                <Typography variant="h6">To reset your password, please enter your email address</Typography>

                {hasError && <Alert severity="error">{errorMessage}</Alert>}
                {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

                <TextField
                  label="Email Address"
                  value={email}
                  onChange={onTextChange}
                  onKeyPress={onFormKeyPress}
                  color="warning"
                />

                <Button variant="contained" color="primary" onClick={resetPasswordClick}>
                  Reset Password
                </Button>
              </Stack>
            )}

            {isSubmitted && (
              <Alert severity="info">Please check your email and follow the instructions to reset your password.</Alert>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ForgotPassword;
