import axios from 'axios';
import fileDownload from 'js-file-download';

import { USER_TOKEN } from '../../../../utils/constants';
import { getTenantIdForDomain, reactapp_APIURL } from '../../../../helpers/tenantHelpers';
import dayjs from 'dayjs';

type ExportResponseData = string | ArrayBuffer | ArrayBufferView | Blob;

const tenantId = getTenantIdForDomain();

// Global Exports
export async function exportAccountsNoRegistration(onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(reactapp_APIURL+'/exportAccountsNoRegistration', `Accounts No Registration_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`, onExportStart);
}

export async function exportAllRegistrants(onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(reactapp_APIURL+'/exportRegistrants', `All Registrants_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`, onExportStart);
}

export async function exportAllEvaluationData(exportType: 'label' | 'value', onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(`${reactapp_APIURL}/evaluationExport?exportType=${exportType}`, `Evaluation-All_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`, onExportStart);
}

// Cohort Exports
export async function exportCohortAttendance(cohortId: string, internalLabel: string, onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(
    `${reactapp_APIURL}/exportAttendance?cohortId=${cohortId}`,
    `Attendance-${internalLabel}_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`,
    onExportStart
  );
}

export async function exportCohortEvaluationData(
  cohortId: string,
  internalLabel: string,
  exportType: 'label' | 'value',
  onExportStart: (isExporting: boolean) => void
) {
  await FetchDataExport(
    `${reactapp_APIURL}/evaluationExport?cohortId=${cohortId}&exportType=${exportType}`,
    `Evaluation-${internalLabel}_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`,
    onExportStart
  );
}

export async function exportCohortPreRegistrants(cohortId: string, internalLabel: string, onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(
    `${reactapp_APIURL}/exportPreRegistrant?cohortId=${cohortId}`,
    `PreRegistrants-${internalLabel}_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`,
    onExportStart
  );
}

export async function exportCohortRegistrants(cohortId: string, internalLabel: string, onExportStart: (isExporting: boolean) => void) {
  await FetchDataExport(
    `${reactapp_APIURL}/exportRegistrants?cohortId=${cohortId}`,
    `Cohort Registrants-${internalLabel}_${dayjs().format('MM_DD_YYYY_HHmmss')}.xlsx`,
    onExportStart
  );
}

const FetchDataExport = async (apiPath: string, downloadFileName: string, onExportStart: (isExporting: boolean) => void) => {
  
  const token = getToken();
  const authorizationHeader = token ? `Bearer ${token}` : '';
  const headers = {
    tenant: tenantId,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Authorization: authorizationHeader,
  };

  try {
    onExportStart(true);
    const response = await axios.get<ExportResponseData>(apiPath, {
      responseType: 'blob',
      headers: headers,
    });
  
    const attendanceData = response.data;
    fileDownload(attendanceData, downloadFileName);
  } catch (err: any) {
    console.error(err.message);
  } finally {
    onExportStart(false); // Always set exporting to false, even if an error occurs
  }
}

function getToken() {
  return localStorage.getItem(USER_TOKEN);
}
