import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Button, Stack, Typography, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent, Alert } from '@mui/material';

import GqlErrorDisplay from 'components/global/GqlErrorDisplay';
import SendEmailDialog from './SendEmailDialog';
import PageHeader from '../../../global/PageHeader';
import ProgressIndicator from '../../../global/ProgressIndicator';

import { getSessionsForCohortQuery_Gql } from 'gql/session/getSessionsForCohortQuery';
import { getRegistrantsForCohortQuery_Gql } from 'gql/cohortRegistration/getRegistrantsForCohortQuery';
import { getAttendanceForSession_Gql } from 'gql/cohortAttendance/getAttendanceForSession';
import {
  GetSessionsByCohort,
  GetSessionsByCohortVariables,
  GetSessionsByCohort_getSessionsByCohort,
  GetRegistrantsForCohort_getRegistrantsForCohort,
  GetAttendanceForSession_getAttendanceForSession,
  GetAttendanceForSessionVariables,
  GetAttendanceForSession,
} from '../../../../models/GeneratedModels';

const R = require('ramda');

export type EmailRecipientType = 'new' | 'registered' | 'waitlisted' | 'denied' | 'session';
export type SessionOptionType = 'All' | 'Present' | 'Absent';

interface CohortEmailProps {
  cohortId: string;
}

const CohortEmail = ({ cohortId }: CohortEmailProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [recipientType, setRecipientType] = useState<EmailRecipientType>('new');
  const [selectedSession, setSelectedSession] = useState('');
  const [selectedSessionName, setSelectedSessionName] = useState('');
  const [selectedSessionOption, setSelectedSessionOption] = useState<SessionOptionType>('All');
  const [newRegistrants, setNewRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [approvedRegistrants, setApprovedRegistrants] = useState<
      GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [deniedRegistrants, setDeniedRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [waitlistedRegistrants, setWaitlistedRegistrants] = useState<
      GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [sessionPresentAttendees, setSessionPresentAttendees] = useState<GetAttendanceForSession_getAttendanceForSession[]>([]);
  const [sessionAbsentAttendees, setSessionAbsentAttendees] = useState<GetAttendanceForSession_getAttendanceForSession[]>([]);

  const [copySuccess, setCopySuccess] = useState('');
  
  const { loading: sessionsLoading, data: sessionsData, error } = useQuery<GetSessionsByCohort, GetSessionsByCohortVariables>(getSessionsForCohortQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  const { loading: registrantsLoading, data: registrantsData, error: registrantDataError } = useQuery(
    getRegistrantsForCohortQuery_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const { loading: attendanceLoading, data: attendanceData, error: attendanceDataError } = useQuery<
    GetAttendanceForSession,
    GetAttendanceForSessionVariables
  >(getAttendanceForSession_Gql, {
    variables: {
      cohortId,
      sessionId: selectedSession,
    },
    skip: selectedSession.length === 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allSessionAttendees = useMemo(
    () => attendanceData?.getAttendanceForSession ?? [],
    [attendanceData]
  );

  useEffect(() => {
    if (attendanceLoading || !attendanceData) return;

    const isPresentAttendees = (x: GetAttendanceForSession_getAttendanceForSession) =>
        x.isPresent;
    setSessionPresentAttendees(R.filter(isPresentAttendees, allSessionAttendees));

    const isAbsentAttendees = (x: GetAttendanceForSession_getAttendanceForSession) =>
      !x.isPresent;
    setSessionAbsentAttendees(R.filter(isAbsentAttendees, allSessionAttendees));
  }, [attendanceLoading, attendanceData, allSessionAttendees]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allRegistrants = useMemo(
    () => registrantsData?.getRegistrantsForCohort ?? [],
    [registrantsData]
  );

  useEffect(() => {
    if (registrantsLoading || !registrantsData) return;
    
    if (allRegistrants) {
      // New registrants
      const isNew = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        !x.isApproved && !x.isWaitlisted && !x.isDenied && x.isActive;

      setNewRegistrants(R.filter(isNew, allRegistrants));

      // Approved registrants
      const isApproved = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isApproved && x.isActive;
      setApprovedRegistrants(R.filter(isApproved, allRegistrants));

      // Denied registrants
      const isDenied = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isDenied && x.isActive;
      setDeniedRegistrants(R.filter(isDenied, allRegistrants));

      // Watlisted registrants
      const isWaitlisted = (
        x: GetRegistrantsForCohort_getRegistrantsForCohort
      ) => x.isWaitlisted && x.isActive;
      setWaitlistedRegistrants(R.filter(isWaitlisted, allRegistrants));
    }
  }, [registrantsLoading, registrantsData, allRegistrants]);

  const sessions: GetSessionsByCohort_getSessionsByCohort[] = sessionsData?.getSessionsByCohort ?? [];

  const getSessionName = (sessionId: string): string => {
    if (sessions.length === 0) return '';

    const index = R.findIndex(R.propEq('id', sessionId))(sessions);
    return sessions[index]?.title ?? '';
  };

  const onSelectElementChange = (e: SelectChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'session-select') {
      setSelectedSession(value);
      setSelectedSessionName(getSessionName(value));
    }

    if (name === 'cohort-type-select') {
      setSelectedSessionOption(value as SessionOptionType);
    }
  };

  const handleClick = (value: EmailRecipientType) => {
    setRecipientType(value);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const stringifyRegistrants = (registrants: GetRegistrantsForCohort_getRegistrantsForCohort[]) => {
    return registrants.map((reg) => {
      return reg.user.email
    }).join(', ');
  };

  const stringyfyAttendanceData = (attendants: GetAttendanceForSession_getAttendanceForSession[] | GetRegistrantsForCohort_getRegistrantsForCohort[]) => {
    return attendants.map((att) => {
      if ('email' in att) {
        return att.email;
      }

      return att.user.email;
    }).join(', ');
  };

  const getSessionAttendants = (): GetRegistrantsForCohort_getRegistrantsForCohort[] | GetAttendanceForSession_getAttendanceForSession[] => {
    if (selectedSessionOption === 'All') {
      return approvedRegistrants;
    }

    if (selectedSessionOption === 'Absent') {
      return sessionAbsentAttendees;
    }

    return sessionPresentAttendees;
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Copied to Clipboard!');
      setTimeout(() => {
        setCopySuccess('');
      }, 3000)
    } catch (err) {
      setCopySuccess('Failed to Copy!');
      setTimeout(() => {
        setCopySuccess('');
      }, 3000)
    }
  };

  return (
    <>
      <ProgressIndicator isOpen={sessionsLoading || registrantsLoading || attendanceLoading} title="Loading..." />
      
      <PageHeader title="Email Center" />

      <GqlErrorDisplay error={error ?? registrantDataError ?? attendanceDataError} />

      <Grid container sx={{ pt: 7 }} spacing={2}>
        <Grid item xs={12}>
          { !!copySuccess &&
            <Alert severity='success'>
              {copySuccess}
            </Alert>
          }
        </Grid>

        <Grid item xs={3} sx={{ width: '90%', textAlign: 'left' }}>
          <Typography variant="h5">Email Learners</Typography>
          <Typography variant="caption">&nbsp;</Typography>
          <Stack spacing={2}>
            <Button
              onClick={() => handleClick('new')}
              disabled={!newRegistrants.length}
              variant="contained"
              color="success"
            >
              Email All New Registrants ({newRegistrants.length})
            </Button>
            <Button
              onClick={() => handleClick('registered')}
              disabled={!approvedRegistrants.length}
              variant="contained"
              color="success"
            >
              Email All Registered Learners ({approvedRegistrants.length})
            </Button>
            <Button
              onClick={() => handleClick('waitlisted')}
              disabled={!waitlistedRegistrants.length}
              variant="contained"
              color="success"
            >
              Email All Waitlisted Learners ({waitlistedRegistrants.length})
            </Button>
            <Button
              onClick={() => handleClick('denied')}
              disabled={!deniedRegistrants.length}
              variant="contained"
              color="success"
            >
              Email All Denied Learners ({deniedRegistrants.length})
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={3} sx={{ width: '90%', textAlign: 'left' }}>
          <Typography variant="h5">Actions</Typography>
          <Typography variant="caption">&nbsp;</Typography>
          <Stack spacing={2}>
            <Button
              onClick={() => copyToClipboard(stringifyRegistrants(newRegistrants))}
              variant="outlined"
              disabled={!newRegistrants.length}
            >
              Copy All New Registrants
            </Button>
            <Button
              onClick={() => copyToClipboard(stringifyRegistrants(approvedRegistrants))}
              variant="outlined"
              disabled={!approvedRegistrants.length}
            >
              Copy All Registered Learners
            </Button>
            <Button
              onClick={() => copyToClipboard(stringifyRegistrants(waitlistedRegistrants))}
              variant="outlined"
              disabled={!waitlistedRegistrants.length}
            >
              Copy All Waitlisted Learners
            </Button>
            <Button
              onClick={() => copyToClipboard(stringifyRegistrants(deniedRegistrants))}
              variant="outlined"
              disabled={!deniedRegistrants.length}
            >
              Copy All Denied Learners
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={6} sx={{ width: '80%', textAlign: 'right' }}>
          <Typography variant="h5">Session-Based Emails</Typography>
          <Typography variant="caption">
            Includes all attendees, including faculty and other non-learners
          </Typography>
          <Typography variant="body1">
            <FormControl sx={{ width: 400, mt: 2, textAlign: 'left' }}>
              <InputLabel id="session-label" color="warning">
                Select a Session
              </InputLabel>
              <Select
                labelId="session-label"
                name="session-select"
                defaultValue=""
                value={selectedSession}
                label="Select a Session"
                color="warning"
                onChange={onSelectElementChange}
              >
                {sessions &&
                  sessions.map((session) => (
                    <MenuItem key={session.id} value={session.id}>
                      {session.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl sx={{ width: 400, mt: 2, textAlign: 'left' }}>
              <InputLabel id="cohort-type-label" color="warning">
                Select Who to Email
              </InputLabel>
              <Select
                labelId="cohort-type-label"
                name="cohort-type-select"
                defaultValue=""
                value={selectedSessionOption}
                label="Select Who to Email"
                color="warning"
                onChange={onSelectElementChange}
              >
                <MenuItem value="All">All ({approvedRegistrants.length})</MenuItem>
                <MenuItem value="Present" disabled={!sessionPresentAttendees || !sessionPresentAttendees.length}>
                  Present {sessionPresentAttendees && sessionPresentAttendees.length ? `(${sessionPresentAttendees.length})` : null}
                </MenuItem>
                <MenuItem value="Absent" disabled={!sessionAbsentAttendees || !sessionAbsentAttendees.length}>
                  Absent {sessionAbsentAttendees && sessionAbsentAttendees.length ? `(${sessionAbsentAttendees.length})` : null}
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button
                onClick={() => copyToClipboard(stringyfyAttendanceData(getSessionAttendants()))}
                variant="outlined"
                disabled={!attendanceData}
              >
                Copy to Clipboard
              </Button>
              <Button
                onClick={() => handleClick('session')}
                variant="contained"
                color="primary"
                disabled={!attendanceData}
              >
                Start Email
              </Button>
            </Stack>
          </Typography>
        </Grid>
      </Grid>

      <SendEmailDialog
        isOpen={openDialog}
        onClose={handleClose}
        recipientType={recipientType}
        cohortId={cohortId}
        sessionId={selectedSession}
        sessionName={selectedSessionName}
        sessionOption={selectedSessionOption}
      />
    </>
  );
};

export default CohortEmail;
