import { useState, useContext, createContext, ReactNode, useEffect } from 'react';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { clarity } from 'react-microsoft-clarity';

import { encrypt } from 'components/account/Password/Password.helpers';
import { LoginUserMutation_Gql } from '../gql/user/loginUserMutation';
import { LoginUser, LoginUserVariables } from '../models/GeneratedModels';
import Auth from '../models/Auth.model';
import { USER_ID, USER_EMAIL, USER_FULLNAME, USER_ROLES, USER_TOKEN } from '../utils/constants';
import { currentEnvironment } from 'helpers/tenantHelpers';

interface AuthContext {
  user: Auth | null;
  signIn: (username: string, password: string) => Promise<LoginResult>;
  signOut: () => void;
  signInErrors: ApolloError | undefined;
  isLoading: boolean;
}

const authContext = createContext<AuthContext>(undefined!);

interface ProvideAuthProps {
  children: ReactNode;
}

export const ProvideAuth = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

interface LoginResult {
  isAdmin: boolean;
  isSuccessful: boolean;
  needsPasswordReset: boolean;
  errorMessage: string | null;
}

export function useProvideAuth() {
  const client = useApolloClient();

  const [user, setUser] = useState<Auth>(getAuthValuesFromSession());
  const [signInErrors, setSignInErrors] = useState<ApolloError | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [logUserIn, { error, loading }] = useMutation<LoginUser, LoginUserVariables>(LoginUserMutation_Gql);

  useEffect(() => {
    setSignInErrors(error);
  }, [error]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const signIn = async (emailAddress: string, password: string): Promise<LoginResult> => {
    try {
      const loginResult = (
        await logUserIn({
          variables: {
            credentials: {
              email: emailAddress,
              password: encrypt(password),
            },
          },
        })
      ).data?.loginUser;

      if (!loginResult) throw new Error('Login failed');

      if (!loginResult.isValid) {
        return {
          isAdmin: false,
          isSuccessful: loginResult.isValid,
          needsPasswordReset: loginResult.needsPasswordReset,
          errorMessage: loginResult.errorMessage,
        };
      }

      const { userProfile } = loginResult;

      if (!userProfile) throw new Error('User profile was null');

      const { roles, id, email, firstName, lastName, token } = userProfile;

      const isAdmin = roles.indexOf('Administrator') > -1 ? true : false;
      const isAlbatross = roles.indexOf('Albatross') > -1 ? true : false;
      const isEchoStaff = roles && roles.indexOf('ECHOStaff') > -1 ? true : false;
      const isDevelopmentStaff = roles && roles.indexOf('development') > -1 ? true : false;
      const isImplementationStaff = roles && roles.indexOf('implementation') > -1 ? true : false;
      const isEvaluationStaff = roles && roles.indexOf('evaluation') > -1 ? true : false;
      const isPartner = roles && roles.indexOf('Partner') > -1 ? true : false;
      const isRegistrant = roles && roles.indexOf('Registrant') > -1 ? true : false;

      try {
        // Check if Clarity has been initialized before calling its methods
        if (clarity.hasStarted()) {
          // Identify the user
          clarity.identify('USER_ID', { userProperty: `${email}` });
          // Cookie consent
          // clarity.consent();
          // Setup a custom tag
          clarity.setTag('user_role_admin', `${isAdmin}`);
          clarity.setTag('user_role_echoStaff', `${isEchoStaff}`);
          clarity.setTag('user_role_partner', `${isPartner}`);
          clarity.setTag('user_full_name', `${firstName} ${lastName}`);
          clarity.setTag('environment', `${currentEnvironment}`);
          // Upgrade session
          // clarity.upgrade('upgradeReason');
        }
      } catch (err) {
        console.log('There was an error setting Clarity variables', err);
      }

      // Used throughout the context to identify the user
      setUser({
        id: id,
        email: email,
        fullName: `${firstName} ${lastName}`,
        token: token,
        userRoles: roles,
        isAuthenticated: true,
        isAdmin,
        isAlbatross,
        isRegistrant,
        isEchoStaff,
        isDevelopmentStaff,
        isImplementationStaff,
        isEvaluationStaff,
        isPartner,
      });

      localStorage.setItem(USER_ID, id || '');
      localStorage.setItem(USER_EMAIL, email || '');
      localStorage.setItem(USER_FULLNAME, `${firstName} ${lastName}`);
      localStorage.setItem(USER_ROLES, roles || '');
      localStorage.setItem(USER_TOKEN, token || '');

      return {
        isAdmin: isAdmin || isAlbatross,
        isSuccessful: true,
        needsPasswordReset: false,
        errorMessage: null,
      };
    } catch (error: any) {
      console.log('signIn Error', error);
      return {
        isAdmin: false,
        isSuccessful: false,
        needsPasswordReset: false,
        errorMessage: error,
      };
    }
  };

  const signOut = () => {
    client.clearStore();

    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_EMAIL);
    localStorage.removeItem(USER_FULLNAME);
    localStorage.removeItem(USER_ROLES);
    localStorage.removeItem(USER_TOKEN);

    setUser(getAuthValuesFromSession());
  };

  function getAuthValuesFromSession(): Auth {
    if (localStorage.getItem(USER_TOKEN) === null) {
      return {
        email: '',
        fullName: '',
        id: '',
        isAdmin: false,
        isAlbatross: false,
        isAuthenticated: false,
        isRegistrant: false,
        isEchoStaff: false,
        isDevelopmentStaff: false,
        isImplementationStaff: false,
        isEvaluationStaff: false,
        isPartner: false,
        token: '',
        userRoles: '',
      };
    }

    const roles = localStorage.getItem(USER_ROLES);
    const isAdmin = roles && roles.indexOf('Administrator') > -1 ? true : false;
    const isAlbatross = roles && roles.indexOf('Albatross') > -1 ? true : false;

    const isEchoStaff = roles && roles.indexOf('ECHOStaff') > -1 ? true : false;
    const isDevelopmentStaff = roles && roles.indexOf('development') > -1 ? true : false;
    const isImplementationStaff = roles && roles.indexOf('implementation') > -1 ? true : false;
    const isEvaluationStaff = roles && roles.indexOf('evaluation') > -1 ? true : false;
    const isPartner = roles && roles.indexOf('Partner') > -1 ? true : false;
    const isRegistrant = roles && roles.indexOf('Registrant') > -1 ? true : false;

    return {
      id: localStorage.getItem(USER_ID),
      email: localStorage.getItem(USER_EMAIL),
      fullName: localStorage.getItem(USER_FULLNAME),
      userRoles: localStorage.getItem(USER_ROLES),
      token: localStorage.getItem(USER_TOKEN),
      isAuthenticated: true,
      isAdmin,
      isAlbatross,
      isRegistrant,
      isEchoStaff,
      isDevelopmentStaff,
      isImplementationStaff,
      isEvaluationStaff,
      isPartner,
    };
  }

  return {
    user,
    signIn,
    signOut,
    signInErrors,
    isLoading,
  };
}
