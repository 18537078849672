import { gql } from '@apollo/client';

export const initiateForgotPassword_Name = 'InitiateForgotPassword';
export const initiateForgotPassword_Gql = gql`
  mutation InitiateForgotPassword($email: String!) {
    initiateForgotPassword(email: $email) {
      isSuccess
      message
    }
  }
`;
