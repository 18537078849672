import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  Box,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  getSessionsForCohortQuery_Gql,
  getSessionsForCohortQuery_Name,
} from '../../../../gql/session/getSessionsForCohortQuery';
import { updateSessionMutation_Gql } from '../../../../gql/session/updateSessionMutation';
import {
  GetSessionsByCohort,
  GetSessionsByCohortVariables,
  GetSessionsByCohort_getSessionsByCohort,
} from '../../../../models/GeneratedModels';
import AddSessionDrawer from '../../Session/AddSessionDrawer';
import ProgressIndicator from '../../../global/ProgressIndicator';
import YesNoDialog from '../../../global/YesNoDialog';
import EditSession from '../../Session/EditSession';
import Attendance from '../../Session/Attendance';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import SortableTableColumn, {
  SortProperty,
  sortBy,
} from 'components/global/SortableTableColumn';
import PageHeader from 'components/global/PageHeader';

dayjs.extend(utc);
dayjs.extend(tz);

const R = require('ramda');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    edit: {
      color: 'goldenrod',
      cursor: 'pointer',
    },
    delete: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    attendanceLink: {
      color: theme.palette.primary.main,
    },
  })
);

interface CohortSessionsProps {
  cohortId: string;
}

const CohortSessions = ({ cohortId }: CohortSessionsProps) => {
  const classes = useStyles();
  const deleteConfirmationText =
    'Are you sure you want to delete this session?';

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sessionToDeactivate, setSessionToDeactivate] =
    useState<GetSessionsByCohort_getSessionsByCohort | null>(null);
  const [showPastChecked, setShowPastChecked] = useState(false);
  const [sortProp, setSortProp] = useState<SortProperty>();

  const { loading, data } = useQuery<
    GetSessionsByCohort,
    GetSessionsByCohortVariables
  >(getSessionsForCohortQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  const getSessionListForDisplay = () => {
    const allSessions = data ? data.getSessionsByCohort ?? [] : [];

    const filteredSessions = allSessions.filter((x) => x.isFuture && x.active);

    return showPastChecked
      ? (sortBy(
          allSessions,
          sortProp
        ) as GetSessionsByCohort_getSessionsByCohort[])
      : (sortBy(
          filteredSessions,
          sortProp
        ) as GetSessionsByCohort_getSessionsByCohort[]);
  };

  const [updateSession, { error }] = useMutation(updateSessionMutation_Gql);

  const setPendingSessionToDeactivate = (
    session: GetSessionsByCohort_getSessionsByCohort
  ) => {
    setSessionToDeactivate(session);
    setConfirmationDialogOpen(true);
  };

  const handleDialogClose = (value: boolean) => {
    setConfirmationDialogOpen(false);

    if (value && sessionToDeactivate) {
      const session = sessionToDeactivate;
      updateSession({
        variables: {
          session: {
            id: session.id,
            cohortId: session.cohortId,
            number: session.number,
            title: session.title,
            startDate: session.startDate,
            endDate: session.endDate,
            active: false,
            learningOutcomes: session.learningOutcomes,
            presenterNotes: session.presenterNotes,
            facultyNotes: session.facultyNotes,
            sendCalendarInvites: false,
          },
        },
        refetchQueries: [getSessionsForCohortQuery_Name],
      });
    }

    setSessionToDeactivate(null);
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPastChecked(event.target.checked);
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading" />

      <PageHeader title="Session Management" />

      <Box sx={{ textAlign: 'left' }}>
        <AddSessionDrawer cohortId={cohortId} />

        <FormControlLabel
          sx={{ mb: 1 }}
          control={
            <Checkbox
              color="primary"
              checked={showPastChecked}
              onChange={handleCheckedChange}
              name="showPastSessions"
            />
          }
          label="Show Past Sessions"
        />

        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="number"
                  headerText="Session Number"
                />
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="title"
                  headerText="Title"
                />
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="startDate"
                  headerText="Start Date"
                />
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="endDate"
                  headerText="End Date"
                />
                <TableCell>Attendance</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSessionListForDisplay().map((session, index) => (
                <TableRow key={session.id}>
                  <TableCell component="th" scope="row">
                    <EditSession 
                      sessionId={session.id}
                      sessionCohortId={session.cohortId}
                      sessionNumber={session.number}
                      sessionTitle={session.title}
                      sessionSartDate={session.startDate}
                      sEndDate={session.endDate}
                      sessionActive={session.active}
                      sessionLearningOutcomes={session.learningOutcomes}
                      sessionPresenterNotes={session.presenterNotes}
                      sessionFacultyNotes={session.facultyNotes}
                     />
                  </TableCell>
                  <TableCell>{session.number}</TableCell>
                  <TableCell>{session.title}</TableCell>
                  <TableCell>
                    {convertDateToTimeZoneFromUtc(session.startDate, true, false, false)}
                  </TableCell>
                  <TableCell>
                    {convertDateToTimeZoneFromUtc(session.endDate, true, false, true)}
                  </TableCell>
                  <TableCell>
                    <Attendance
                      cohortId={cohortId}
                      sessionId={session.id}
                      sessionName={session.title ?? ''}
                      sessionStartDate={session.startDate ?? ''}
                      sessionEndDate={session.endDate ?? ''}
                    />
                  </TableCell>
                  <TableCell>
                    <FaTimes
                      title="Delete Session"
                      className={classes.delete}
                      onClick={() => setPendingSessionToDeactivate(session)}
                    />
                    <YesNoDialog
                      dialogTitle="Delete Session"
                      confirmText="Delete"
                      dialogText={deleteConfirmationText}
                      open={confirmationDialogOpen}
                      onClose={handleDialogClose}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CohortSessions;
