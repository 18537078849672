import { gql } from '@apollo/client';

export const getMyAccount_Name = 'GetMyAccount';
export const getMyAccount_Gql = gql`
  query GetMyAccount {
    getMyAccount {
      id
      firstName
      lastName
      email
      otherCredential
      professionId
      secondLevelProfessionId
      thirdLevelProfessionId
      otherProfession
      otherWorkSetting
      otherEthnicity
      otherMarketingSource
      otherSex
      organization
      organizationAddress1
      organizationAddress2
      city
      state
      countyId
      zip
      provideDirectCare
      provideMedicaid
      primaryCare
      createdAt
      updatedAt
      credentials
      receiveEmails
      userCredentialIds
      userEthnicityIds
      partnerId
      phone
      birthYear
      professionStartYear
      firstSeries
      organizationDescriptionIds
      workSettingIds
      userTenantIds
      sexId
      marketingSourceIds
      roleDescriptionId
    }
  }
`;
