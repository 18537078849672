import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Box, Alert } from '@mui/material';

import LearnerAccountDetail from 'components/account/LearnerAccountDetail/LearnerAccountDetail';
import ProgressIndicator from 'components/global/ProgressIndicator';

import {
  AddOrUpdateUserInput,
  UpdateUserVariables,
  GetMyAccount,
  GetMyAccount_getMyAccount,
} from 'models/GeneratedModels';
import { getUserById_Name } from 'gql/user/getUserById';
import { updateUser_Gql } from 'gql/user/updateUser';
import { getMyAccount_Gql } from 'gql/user/getMyAccount';

const MyLearnerAccount = () => {
  const history = useHistory();

  const [formError, setFormError] = useState('');

  const {
    data,
    loading,
    error: loadingError,
  } = useQuery<GetMyAccount>(getMyAccount_Gql, {
    fetchPolicy: 'no-cache',
  });

  const account: GetMyAccount_getMyAccount | null = data?.getMyAccount ?? null;

  const convertUserToEdit = (): AddOrUpdateUserInput => {
    return {
      city: account?.city ?? '',
      countyId: account?.countyId ?? '',
      credentials: account?.userCredentialIds,
      email: account?.email ?? '',
      ethnicities: account?.userEthnicityIds,
      firstName: account?.firstName ?? '',
      id: account?.id ?? '',
      lastName: account?.lastName ?? '',
      organization: account?.organization ?? '',
      organizationAddress1: account?.organizationAddress1 ?? '',
      organizationAddress2: account?.organizationAddress2 ?? '',
      otherCredential: account?.otherCredential ?? '',
      otherEthnicity: account?.otherEthnicity ?? '',
      otherProfession: account?.otherProfession ?? '',
      otherMarketingSource: account?.otherMarketingSource ?? '',
      otherWorkSetting: account?.otherWorkSetting ?? '',
      otherSex: account?.otherSex ?? '',
      primaryCare: account?.primaryCare,
      professionId: account?.professionId ?? '',
      provideDirectCare: account?.provideDirectCare,
      provideMedicaid: account?.provideMedicaid,
      receiveEmails: account?.receiveEmails || false,
      secondLevelProfessionId: account?.secondLevelProfessionId ?? '',
      state: account?.state ?? '',
      thirdLevelProfessionId: account?.thirdLevelProfessionId ?? '',
      zip: account?.zip ?? '',
      marketingSourceIds: account?.marketingSourceIds ?? [],
      organizationDescriptionIds: account?.organizationDescriptionIds ?? [],
      roleDescriptionId: account?.roleDescriptionId ?? '',
      phone: account?.phone ?? '',
      workSettingIds: account?.workSettingIds ?? [],
      birthYear: account?.birthYear,
      firstSeries: account?.firstSeries,
      professionStartYear: account?.professionStartYear,
      sexId: account?.sexId ?? '',
      userTenantIds: account?.userTenantIds ?? [],
    };
  };

  const [updateUserAccount, { error: savingError, loading: updateLoading }] = useMutation<void, UpdateUserVariables>(
    updateUser_Gql
  );

  const updateUser = async (user: AddOrUpdateUserInput) => {
    setFormError('');

    try {
      await updateUserAccount({
        variables: {
          user,
        },
        refetchQueries: [getUserById_Name],
      });

      if (!savingError) {
        history.push('/');
      }
    } catch (error: any) {
      setFormError(error.graphQLErrors[0].message);
    }
  };

  return (
    <>
      {formError.length > 0 && <Alert severity="error">{formError}</Alert>}

      {loadingError && <Alert severity="error">{loadingError.graphQLErrors[0].message}</Alert>}

      {savingError && <Alert severity="error">{savingError.graphQLErrors[0].message}</Alert>}

      <ProgressIndicator isOpen={loading} title="Loading..." />

      <ProgressIndicator isOpen={updateLoading} title="Saving..." />

      <Box sx={{ mt: 3 }}>
        {!loading && (
          <LearnerAccountDetail
            userAccount={convertUserToEdit()}
            onSave={updateUser}
            buttonText="Save Account"
            submissionError={formError}
            mode="update"
          />
        )}
      </Box>
    </>
  );
};

export default MyLearnerAccount;
