import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DropzoneArea } from 'react-mui-dropzone';

// MUI
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

// Components
import PageHeader from '../../../global/PageHeader';
import ProgramBreadcrumb from '../ProgramBreadcrumb';
import ProgramParams from '../../../../models/ProgramParams.model';
import ProgressIndicator from 'components/global/ProgressIndicator';
import ResourceList from './components/ResourceList';
import UrlResource from './components/UrlResource';

// Hooks
import { useUploadResources } from './hooks/useUploadResources';

// GQL and Models
import { getProgramById_Gql } from 'gql/program/getProgramByIdQuery';
import { getResourceForProgram_Gql } from 'gql/programResources/getResourceForProgram';
import {
  GetResourceByProgram,
  GetResourceByProgram_getResourceByProgram,
  GetProgramById,
  GetProgramByIdVariables,
  GetProgramById_getProgramById,
} from 'models/GeneratedModels';
import { Container } from '@mui/material';

const ManageResources = () => {
  let { id } = useParams<ProgramParams>();
  const { addResourcesToS3AndDB, uploadingFiles } = useUploadResources();

  const [showUpload, setShowUpload] = useState(false);

  const { loading: programLoading, data: programData } = useQuery<GetProgramById, GetProgramByIdVariables>(
    getProgramById_Gql,
    {
      variables: {
        id,
      },
    }
  );

  const { loading: resourcesLoading, data: resourceData } = useQuery<GetResourceByProgram>(getResourceForProgram_Gql, {
    variables: {
      programId: id,
    },
  });

  const uploadFiles = async (files: File[]) => {
    await addResourcesToS3AndDB(id, files);
  };

  const program: GetProgramById_getProgramById | null | undefined = programData?.getProgramById;
  const resources: GetResourceByProgram_getResourceByProgram[] = resourceData?.getResourceByProgram ?? [];

  return (
    <>
      <Container maxWidth="xl" sx= {{ pt: 10, mb: 12 }}>
        <ProgramBreadcrumb programId={id} currentPageText="Resources" />

        <PageHeader title={`Resources for ${program?.name ?? ''}`} />

        <ProgressIndicator isOpen={programLoading || resourcesLoading} title="Loading..." />
        <ProgressIndicator isOpen={uploadingFiles} title="Saving..." />

        <Box sx={{ textAlign: 'left', marginTop: 3, marginBottom: 3 }}>
          {showUpload && (
            <>
              {/* @ts-ignore */}
              <DropzoneArea<any>
                filesLimit={7}
                showFileNames 
                showPreviews={false} 
                onDrop={uploadFiles} 
                maxFileSize={50000000}
              />
            
              <Alert sx={{ mt: 2 }} severity="warning">
                Note: You can only upload up to 7 files at a time and the maximum size for a file is 50 MB.  For larger file sizes consider uploading one at a time.
              </Alert>

              <Button
                sx={{ mt: 3, mb: 3 }}
                startIcon={<CheckIcon />}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowUpload(false)}
              >
                Finished Uploading Files
              </Button>
            </>           
          )}

          {!showUpload && (
            <>
              <Button
                sx={{ mb: 3, mr: 3 }}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowUpload(true)}
                startIcon={<AddIcon />}
              >
                Upload Files
              </Button>

              <UrlResource programId={id} />
            </>
          )}

          <ResourceList resources={resources} />
        </Box>
      </Container>
    </>
  );
};

export default ManageResources;
