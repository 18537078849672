import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import generateS3Link from 'helpers/s3Helpers';
import { RESOURCE_TYPE_URL } from 'utils/constants';

interface LearnerCohortDashboardProps {
  displayName: string | null;
  name: string;
  programId: string;
  resourceTypeId: string | null;
  url: string | null;
}

const LearnerCohortDashboard = ({ displayName, name, programId, resourceTypeId, url }: LearnerCohortDashboardProps) => {
  const getUrl = () => {
    if (resourceTypeId === RESOURCE_TYPE_URL) return url ?? '';

    return generateS3Link(programId, name);
  };
  return (
    <>
      {resourceTypeId === RESOURCE_TYPE_URL ? (
        <LinkIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
      ) : (
        <FileDownloadIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
      )}

      <Link target="_blank" href={getUrl()}>
        {displayName ?? name}
      </Link>
    </>
  );
};

export default LearnerCohortDashboard;
