import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Breadcrumbs, Container, Link, Typography } from '@mui/material';

import { GetCohortByIdQuery_Gql } from '../../gql/cohort/getCohortByIdQuery';
import { GetCohortById, GetCohortById_getCohortById } from '../../models/GeneratedModels';
import CohortParams from '../../models/params/CohortParams';

import EditCohort from '../../components/admin/cohort/EditCohort';
import RegistrationQuestions from '../../components/admin/cohort/RegistrationQuestions';
import CohortDetails from '../../components/admin/cohort/CohortDetails';
import CohortSessions from '../../components/admin/program/cohort/CohortSessions';
import CohortRegistrants from '../../components/admin/program/cohort/CohortRegistrants';
import CohortEmail from '../../components/admin/program/cohort/CohortEmail';
import HubTeamList from '../../components/admin/hubTeam/HubTeamList';
import CohortBottomNav from '../../components/admin/program/cohort/CohortBottomNav';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';

import { useAuth } from 'hooks/useAuth';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    bottomBuffer: {
      paddingBottom: 55,
    },
    breadcrumbLink: {
      color: theme.palette.warning.main,
    },
  })
);

const CohortDashboard = () => {
  const classes = useStyles();
  let { cohortId } = useParams<CohortParams>();

  const [selectedComponent, setComponent] = useState(0);
  const [cohort, setCohort] = useState<GetCohortById_getCohortById>();

  const { loading, data } = useQuery<GetCohortById>(GetCohortByIdQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  useEffect(() => {
    if (loading) return;

    setCohort(data?.getCohortById);
  }, [data, loading]);

  const switchComponent = (component: number) => {
    setComponent(component);
  };

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff || auth.user?.isPartner;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
        {isAuthorized() && (
        <Container
          maxWidth="xl"
          sx={{
            p: 10,
            mb: 2,
            '& ul, & ol': {
              marginLeft: '17px'
            }
          }}
        >
          <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 25 }}>
            <Link color="inherit" href="/admin/current-projects">
              Current Projects
            </Link>
            <RouterLink className={classes.breadcrumbLink} to={`/admin/program/dashboard/${data?.getCohortById.programId}`}>
              Program Dashboard
            </RouterLink>
            <Typography color="textPrimary">{cohort?.internalLabel}</Typography>
          </Breadcrumbs>

          {cohort && (
            <>
              {selectedComponent === 0 ? <CohortDetails cohortId={cohort.id} /> : null}

              {selectedComponent === 1 ? <EditCohort cohort={cohort} /> : null}

              {selectedComponent === 2 ? (
                <RegistrationQuestions registrationQuestions={cohort.registrationQuestions} cohortId={cohort.id} />
              ) : null}

              {selectedComponent === 3 ? <CohortSessions cohortId={cohort.id} /> : null}

              {selectedComponent === 4 ? (
                <CohortRegistrants cohortId={cohort.id} internalLabel={cohort.internalLabel} />
              ) : null}

              {selectedComponent === 5 ? <CohortEmail cohortId={cohort.id} /> : null}

              {selectedComponent === 6 ? <HubTeamList /> : null}
            </>
          )}

          <div className={classes.bottomBuffer}>&nbsp;</div>

          <CohortBottomNav activeComponent={selectedComponent} switchComponent={switchComponent} />        
        </Container>
        )}
      </>
    )
  );
};

export default CohortDashboard;
